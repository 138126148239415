.home-container {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
    box-sizing: border-box;
    overflow-x: hidden;
    
    
}
.home-first-element-container-tel {
    display: none;
}

.home-bg-1 {
    position: absolute;
    background-image: linear-gradient(to top, #ffffff, #FFEEBC);
    height: 250px;
    width: 100%;
    top: 0;
}

.home-bg-2{
    height: 80%;
    width: 100%;
    background-image: linear-gradient(to top,  #FFEEBC, #ffffff);
    top: 0;
    position: absolute;
    margin-top: 400px;
}

.home-bg-3{
    height: 25%;
    width: 100%;
    background-image: linear-gradient(to top,  #ffffff, #FFEEBC);
    bottom: 0;
    position: absolute;
    z-index: 0;

}

.ligne {
    position: absolute;
    width: 500px;
    border: 0.5px solid black;
    top: -20px;
}
.home-child {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-top: 60px;
    box-sizing: border-box;
    z-index: 2;
    min-width: 1200px;
    
}

.home-first-element-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6%;
    max-width: 2000px;
    margin-bottom: 60px;
}

.home-first-element-left {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 40%;
    gap: 35px;
}

.home-first-element-right {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 20%;
}

.home-first-element-title {
    font-size: 40px;
    font-weight: bold;
    line-height: 55px;
   
}

.home-first-element-text {
    line-height: 28px;
}

.home-button {
    height: 40px;
    width: 150px;
    background-color: #1B4722;
    border-radius: 10px;
    border: none;
    outline: none;
    cursor: pointer;
    color: white;
    font-weight: 600;
}

.home-button:active{ 
    transform: scale(1.05);
}

.home-first-element-right-img {
    height: 100%;
    width: 370px;
}

.home-second-element-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15%;
    width: 100%;
    flex-direction: column;
    position: relative;
    z-index: 3;
    max-width: 2000px;
}

.home-second-element 
{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
    width: 100%;
    gap: 10%;
    height: 500px;
    z-index: 2;
}



.home-second-element-img {
    width: 380px;
}

.home-second-element-text {
    width: 35%;
    gap: 25px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    

}

.home-second-element-title {
    font-size: 30px;
    font-weight: bold;
    line-height: 35px;
   
}

.home-first-element-text_p {
    line-height: 30px;
}

#asperge_champs {
    width: 320px;
}

#img_champs {

}

.home-third-element {
    height: 450px;
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 2;
    
}
.home-third-element-child{
    height: 450px;
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 2;
    max-width: 2000px;
}

.home-third-element-container-tarif {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2%;
    margin-top: 40px;
}

.home-third-tarif {
    background-color: #FFEEBC;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 180px;
    width: 280px;
    border-radius: 10px;
    gap: 10px;
    position: relative;
}

.home-third-tarif {
    transition: transform 0.5s;
}

.home-third-tarif:hover {
    transform: scale(1.1);
}

.home-third-title {
    font-size: 20px;
    font-weight: 500;
}

.home-third-price {
    font-size: 70px;
    height: 80px;
    font-weight: 100;
}

#middle-tarif {
    height: 210px;
    width: 360px;
}

#midle-title {
    font-size: 30px;
}

#midle-price {
    font-size: 85px;
    height: 90px;
}

.home-fourth-element {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 2000px;

}

.home-fourth-container {
    height: 400px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10%;
}
.home-third-element-img {
    width: 500px;
}

.home-footer {
    height: 150px;
    width: 100%;
    max-width: 2000px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    padding: 20px;
    margin-top: 50px;
    font-size: 13px;
}

.footer-popup {
    height: auto;
    width: 350px;
    background-color: #FFEEBC;
    position: absolute;
    right: 100px;
    bottom: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 20px;
    flex-direction: column;
}

.home-footer-container-reseaux {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.home-footer-a {
    cursor: pointer;
}
.home-footer-a:hover {
    transform: scale(1.05);
}

.home-footer-a:active {
    transform: scale(1.05);
}

.home-fourth-element-text_p {
    line-height: 35px;
}

.logo-euro {
    
    font-size: 25px;
}
#logo-euro-left{
    right: 120px;
}

#logo-euro-midle{
    right: 85px;
    top: 140px;
}

#home-button-middle {
    background-color: transparent;
    border:1px solid #1B4722;
    color:#1B4722;
}

.home-third-price-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-logo {
    position: absolute;
    opacity: 0;
}
.home-third-element-img-tel{
    display: none;
}
.home-second-element-img-tel {
    display: none;
}
.footer_text_tel {
    display: none;
}
.header-popup-main {
    display: none;
}
.footer_text_tel_infos {
    display: none;
}
.footer_text_infos {
    font-size: 11px;
}

.footer-barre {
    width: 100px;
    border: 0.5px solid black;
    margin-top: 5px;
    margin-bottom: 5px;
  
}
@media only screen and (max-width: 1200px) {
    .home-container {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 80px;
        box-sizing: border-box;
        
        
    }
    .home-first-element-title {
        font-size: 30px;
        font-weight: bold;
        line-height: 30px;
        text-align: center;
        width: 120%;
       
    }
    
    .home-first-element-text {
        line-height: 28px;
    }
    .home-first-element-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10%;
        max-width: 2000px;
        margin-bottom: 60px;
        flex-wrap: wrap;
        display: none;
    }
    .home-first-element-container-tel {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10%;
        max-width: 2000px;
        margin-bottom: 60px;
        flex-direction: column;
      
    }
    .home-child {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        padding-top: 80px;
        box-sizing: border-box;
        z-index: 2;
        min-width: 300px;
        max-width: 600px;

        
    }
    .home-first-element-left {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 80%;
        gap: 35px;
        min-width: 300px;
        text-align: justify;
    }
    .home-first-element-right {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 20%;
        
    }
    .home-second-element-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;
        width: 100%;
        flex-direction: column;
        position: relative;
        z-index: 3;
        max-width: 2000px;
        flex-wrap: wrap;
        
    }
    .home-first-element-right-img{
        width: 250px;
        margin-top: 50px;
        z-index: 1;
    }
    
    .home-second-element 
    {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 300px;
        width: 100%;
        gap: 50px;
        height: auto;
        z-index: 2;
        flex-direction: column;
        margin-top: 50px;
    }
    
    
    
    .home-second-element-img {
        width: 380px;
        display: none;
    }
    .home-second-element-img-tel {
        width: 240px;
        display: flex;
    }
    
    .home-second-element-text {
        width: 80%;
        gap: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
       
        
    
    }
    
    .home-second-element-title {
        font-size: 30px;
        font-weight: bold;
        line-height: 35px;
        text-align: center;
       
    }
    
    .home-first-element-text_p {
        line-height: 25px;
        text-align: justify;
    }
    
    #asperge_champs {
        width: 240px;
    }
    #asperge_caisse{
      
    }
    #img_champs {
    
    }
    .home-third-element {
        height: auto;
        background-color: white;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 2;
        margin-top: 50px;
 
        box-sizing: border-box;
        margin-bottom: 50px;
     
    }
    .home-third-element-child{ 
        height: auto;
        background-color: white;
        width: 100%;
        max-width: 800px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 2;
        padding-top: 50px;
        box-sizing: border-box;
        padding-bottom: 50px;
    }
    
    .home-third-element-container-tarif {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 25px;
        margin-top: 40px;
        flex-direction: column;
    }
    
    .home-third-tarif {
        background-color: #FFEEBC;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 160px;
        width: 280px;
        border-radius: 10px;
        gap: 10px;
        position: relative;
    }
    
    .home-third-tarif {
        transition: transform 0.5s;
    }
    
    .home-third-tarif:hover {
        transform: scale(1.1);
    }
    
    .home-third-title {
        font-size: 20px;
        font-weight: 500;
    }
    
    .home-third-price {
        font-size: 70px;
        height: 80px;
        font-weight: 100;
    }
    
    #middle-tarif {
        height: 190px;
        width: 340px;
    }
    
    #midle-title {
        font-size: 30px;
        
    }
    
    #midle-price {
        font-size: 85px;
        height: 90px;
    }
    .home-fourth-element {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 2000px;
        
    
    }
    
    .home-fourth-container {
        height: auto;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 40px;
        flex-direction: column;
        text-align: justify;
        margin-top: 50px;
    }
    .home-third-element-img {
        width: 320px;
        display: none;
    }
    .home-third-element-img-tel{
        width: 320px;
        display: flex;
    }
    
    .home-footer {
        height: auto;
        width: 100%;
        max-width: 2000px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        box-sizing: border-box;
        padding: 20px;
        margin-top: 50px;
        text-align: center;
        font-size: 10px;
        gap: 5px;
       
    }
    
    .home-footer-container-reseaux {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
    
    .home-footer-a {
        cursor: pointer;
    }
    
    .home-footer-a:active {
        transform: scale(1.05);
    }
    
    .home-fourth-element-text_p {
        line-height: 25px;
    }
    
    .logo-euro {
        font-size: 25px;
    }
    #logo-euro-left{

    }
    
    #logo-euro-midle{
  
    }
    
    #home-button-middle {
        background-color: transparent;
        border:1px solid #1B4722;
        color:#1B4722;
    }
    
    .home-logo {
        position: absolute;
        opacity: 0;
    }
    .ligne {
        position: absolute;
        width: 200px;
        border: 0.5px solid black;
        top: -20px;
    }
    .home-third-tarif:hover {
        transform: none;
    }
    .footer_text_tel {
        display: flex;
    }
    .footer_text{
        display: none;
    }
    .footer_text_tel_infos {
        color: grey;
        display: flex;
    }
    .header-popup-main {
        height: auto;
        width: 350px;
        background-color: #FFEEBC;
        margin-top: 50px;
        right: 13vw;
        top: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        box-sizing: border-box;
        padding: 20px;
        flex-direction: column;
       
    }
    .footer_text_infos{
     display: none;
    }
}