
html, body, #root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none;
  color: inherit; 
  cursor: auto; 
}