.header-container {
    height: 130px;
    width: 100%;
    position: absolute;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.header-child {
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 2000px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    padding-left: 2%;
    padding-right: 3%;
    
}
.header-logo {
    height: 150px;
    margin-top: 30px;

}

.header-container-menu {
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 50px;
    
}

.header-menu-p {
    cursor: pointer;
}

.header-menu-p:active{
    transform: scale(1.05);
}
.header-popup {
    height: auto;
    width: 350px;
    background-color: #FFEEBC;
    position: absolute;
    right: 13vw;
    top: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 20px;
    flex-direction: column;
    border: 1px solid #1B4722;
}

.header-popup-contact {
    height: auto;
    width: 350px;
    background-color: #FFEEBC;
    position: absolute;
    right: 5vw;
    top: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 20px;
    flex-direction: column;
    border: 1px solid #1B4722;
}

.header-popup-contenu {
    min-height: 40px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-popup-title {
    font-weight: 600;
    font-size: 17px;
    margin-bottom: 15px;
}
@media only screen and (max-width: 1200px) {
    .header-child {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 2000px;
        padding-left: 10%;
        padding-right: 10%; 
        width: 100%;
        box-sizing: border-box;
        padding-top: 20px;
    }
    .header-container-menu {
        display: none;
    }
    .header-logo {
        height: 120px;
    
    }
    .header-container {
        height: 100px;
        width: 100%;
        position: absolute;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .header-popup {
        display: none;
    }
}